.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

}

.main {
  flex: 1;
}

@media (min-width: 768px) {
  /* .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  } */


}

.top {
  
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 20px;
}

.header {
  margin: 44px 0px;
  line-height: 44px;

}

.logo {
  height: 90px;
  width: 340px!important;
}

.title {
  font-size: 33px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.desc {
  font-size: 18px;
  color: var(--primaryDark);
  margin-top: 12px;
  margin-bottom: 40px;
}
