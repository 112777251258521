/* 
:global {
  .drawer .drawer-content {
    background: var(--primary);
  }

  .ant-menu-item {
    &-selected {
      a {
        color: red !important;
      }
    }
  }
  
  .ant-menu-item-selected {
    a {
      color: red !important;
    }
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    box-shadow: none;
  }

  .ant-menu-item .ant-menu-item-selected {
    background-color: black !important;
  }

  

  .ant-menu-inline-collapsed {
    & > .ant-menu-item .sider-menu-item-img + span,
    & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
    & > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
      max-width: 0;
      display: inline-block;
      opacity: 0;
      font-size: 20px;
    }
  }
  .ant-menu-item .sider-menu-item-img + span,
  .ant-menu-submenu-title .sider-menu-item-img + span {
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), width 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    opacity: 1;
    font-size: 20px;
  }
} */



.sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;

  
}

.menu {
  padding: 16px 0px;
  width: 100%;
}

.image_logo {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
  padding-left: 13px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.text_logo {
  padding-left: 20px;
}

.text_logo img {
  width: 100%;
  height: 100%;
}

.ant-menu-item-active:hover .li {
  background-color: #007b33;
  padding-left: 0px;
}

.ant_menu_title_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.highlight {
  color: #007b33 !important;
}

.icon {
  margin-right: 20px;
  opacity: 0.6;
  overflow: visible !important;
}

.my_menu_item {
  padding-left: 30px !important;
  .ant-menu-item-selected& {
    color: var(--ant-primary-color);
  }
}

.li {
  margin-left: 12px;
  font-size: 16px;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #B039CC !important;
} */
