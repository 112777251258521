body {
  --ant-primary-color: #00a750;
  --ant-primary-color-hover: #009f43;
  --ant-primary-color-active: #007b33;
  --ant-primary-color-outline: #007b33;
  --ant-primary-1: #e6ffea;
  --ant-primary-2: #baffca;
  --ant-primary-3: #91ff9e;
  --ant-primary-4: #69ff6e;
  --ant-primary-5: #40ff66;
  --ant-primary-6: #18ff46;
  --ant-primary-7: #09d909;
  --ant-primary-color-deprecated-pure: ;
  --ant-primary-color-deprecated-l-35: #d2ffcb;
  --ant-primary-color-deprecated-l-20: #9aff7e;
  --ant-primary-color-deprecated-t-20: #65ff46;
  --ant-primary-color-deprecated-t-50: #92ff8c;
  --ant-primary-color-deprecated-f-12: rgba(24, 255, 97, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 255, 239, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcffe7;
  --ant-success-color: #52c41a;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-active: #389e0d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-error-color: #ff4d4f;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-active: #d9363e;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;
  --ant-box-shadow-color: rgba(42, 140, 0, 0.2);
}

:global {
  .ant-menu-item-selected {
    background-color: var(--ant-primary-1) !important;
    a,
    a:hover {
      color: var(--ant-primary-color);
    }
  }

  .ant-menu-item {
    a:hover {
      color: var(--ant-primary-color);
    }

    &:after {
      border-right: 3px solid var(--ant-primary-color) !important;
    }
  }

  .ant-tooltip-inner {
    background-color: white;
    color: var(--ant-primary-color) !important;
    svg,
    span {
      color: var(--ant-primary-color) !important;
    }
  }

  .ant-spin {
    color: var(--ant-primary-color-6) !important;
    .ant-spin-dot-item {
      background-color: var(--ant-primary-color) !important;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    border-color: var(--ant-primary-color-hover) !important;
    color: var(--ant-primary-color-hover);
    svg {
      color: var(--ant-primary-color-hover) !important;
    }

    &.ant-btn-primary:hover,
    &.ant-btn-primary:focus,
    &.ant-btn-primary:active {
      color: #fff !important;
      svg {
        color: #fff !important;
      }
    }
  }

  .ant-btn-primary {
    color: #fff;
    border-color: var(--ant-primary-color-active) !important;
    background: var(--ant-primary-color-active) !important;

    &:hover,
    &:focus,
    &:active {
      color: #fff !important;
      background: var(--ant-primary-color-hover) !important;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--ant-primary-color) !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--ant-primary-color) !important;
    box-shadow: 0 0 0 2px var(--ant-box-shadow-color);
  }

  .ant-picker-focused,
  .ant-select-focused,
  .ant-picker:hover,
  .ant-select:hover,
  .ant-input-number:hover,
  .ant-input-number:focus,
  .ant-input-number-focused,
  input:hover,
  input:focus,
  .ant-input:hover,
  .ant-input:focus {
    border-color: var(--ant-primary-color) !important;
    outline-color: var(--ant-primary-color) !important;
    box-shadow: 0px 0px 0.25px 0.25px var(--ant-primary-color-hover) !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--ant-primary-color-hover) !important;
  }

  .ant-radio-inner::after {
    background-color: var(--ant-primary-color-hover) !important;
  }

  .ant-radio-checked::after {
    border: 1px solid var(--ant-primary-color-hover) !important;
  }

  .ant-pagination-item {
    a:hover,
    a:focus,
    a:visited,
    a:active {
      color: var(--ant-primary-color-hover) !important;
    }
  }

  .ant-pagination-item:hover,
  .ant-pagination-item:focus,
  .ant-pagination-item-active,
  .ant-pagination-item-link:hover,
  .ant-pagination-item-link:focus,
  .ant-pagination-item-link:active {
    border-color: var(--ant-primary-color-hover) !important;
    a {
      color: var(--ant-primary-color-hover) !important;
    }
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: var(--ant-primary-color-hover) !important;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #fff;
    border-color: var(--ant-primary-color-hover) !important;
  }

  .ant-switch-checked {
    background-color: var(--ant-primary-color-hover) !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--ant-primary-color-hover) !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: var(--ant-primary-color-hover) !important;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--ant-primary-color-hover) !important;
  }

  .ant-list-item-action,
  .ant-list-item-meta-description {
    a {
      color: var(--ant-primary-color-hover) !important;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--ant-primary-color-hover) !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--ant-primary-color-hover) !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px var(--ant-box-shadow-color) !important;
  }

  .ant-picker-focused {
    .ant-input {
      border-color: var(--ant-primary-color-hover) !important;
    }
  }

  .ant-picker-range .ant-picker-active-bar {
    background: var(--ant-primary-color) !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--ant-primary-color) !important;
    border-color: var(--ant-primary-color) !important;
  }
}
