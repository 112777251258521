body {
  --ant-primary-color: #00A750;
  --ant-primary-color-hover: #21DB6E;
  --ant-primary-color-active: #007B33;
  --ant-primary-color-outline: #007B33;
  --ant-primary-1: #e6ffea;
  --ant-primary-2: #baffca;
  --ant-primary-3: #91ff9e;
  --ant-primary-4: #69ff6e;
  --ant-primary-5: #40ff66;
  --ant-primary-6: #18ff46;
  --ant-primary-7: #09d909;
  --ant-primary-color-deprecated-pure: ;
  --ant-primary-color-deprecated-l-35: #d2ffcb;
  --ant-primary-color-deprecated-l-20: #9aff7e;
  --ant-primary-color-deprecated-t-20: #65ff46;
  --ant-primary-color-deprecated-t-50: #92ff8c;
  --ant-primary-color-deprecated-f-12: rgba(24, 255, 97, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 255, 239, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcffe7;
  --ant-success-color: #52c41a;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-active: #389e0d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-error-color: #ff4d4f;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-active: #d9363e;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;  
}





