.main {
  width: 368px;
  margin: 0 auto;
  font-family: 'Avenir', sans-serif;
}

.icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}

.container{
  margin: auto;
  max-width: 1200px;
}

.header{
  font-size: 40px;
  font-weight: 900;
  color: #007B33;
  text-align: center;
  margin-bottom: 12px;
}

.callback{
  font-size: 25px;
  font-weight: 900;
  border: none;
}

.label{
  font-size: 20px !important;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.forgetPassword{
  float: right;
}

.forgetPassword a{
 font-size: 16px ;
 color: #007B33;
}


.login {
  & :global(.ant-form-item) {
    margin-bottom: 12px;
  }

  & .submit {
    width: 100%;
    margin-top: 10px;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: aliceblue;
    background: linear-gradient(91.79deg, #007B33 0%, #00A750 105.88%);
  }

  & .active {
    width: 100%;
    margin-top: 10px;
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: aliceblue;
    background-color: #007B33;
  }

  & .login-form {
    max-width: 300px;
  }
}
